<template>
  <div class="article-box" v-loading="loading">
    <div>
      <div class="pag-title clearfix" style="margin-top:0">
        <div class="page-title-name pull-left">会费管理</div>
      
    </div>
    <div class="search-box clearfix">
        <div class="search-label pull-left">单位全称或账号:</div>
        <div class="search-item pull-left">
            <el-input v-model="text" clearable size="small" style="width: 200px;height: 32px" placeholder="请输入单位全称或账号">
            </el-input>
        </div>
        <div class="search-label pull-left">付款时间:</div>
        <div class="search-item pull-left">
            <el-date-picker
              v-model="value1"
              size="small"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
        </div>
    </div>
    <div class="search-box clearfix">
        <div class="search-label pull-left">本机构等级:</div>
        <div class="search-item pull-left">
              <el-select v-model="value2" placeholder="请选择" size="small">
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
        </div>
        <div class="search-label pull-left">资金状态:</div>
        <div class="search-item pull-left">
          <el-select v-model="value3" placeholder="请选择" size="small">
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="search-label pull-left">	发票状态:</div>
        <div class="search-item pull-left">
          <el-select v-model="value4" placeholder="请选择"  size="small">
            <el-option
              v-for="item in options3"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option> </el-select>
        </div>
        <div class="button-item button-search pull-left" @click="search">查询</div>
      </div>
    </div>
    <div style="margin-top:20px ">
      <el-table
        border
        :data="list"
        highlight-current-row
        header-row-class-name="table-header-class"
        :header-cell-style="{ 'background-color': '#F7F8FA' }"
        style="width: 100%; min-height: 420px"
      >
        <el-table-column type="index" prop="createDate" label="序号" >
        </el-table-column>
        <el-table-column
          prop="loginName"
          label="账号（企业信用代码）"
          align="center"
          min-width="20%"
        >
        </el-table-column>
        <el-table-column
          prop="nickName"
          label="单位名称"
          align="center"
          min-width="20%"
        >
        </el-table-column>
        <el-table-column
          prop="payWay"
          label="付款方式"
          align="center"
          min-width="20%"
        >
          <!-- 1.银行账户付款 2.微信付款 3.支付宝付款 4.银联支付 , -->
          <template slot-scope="scope">
            <span v-if="scope.row.payWay === 1">银行账户付款</span>
            <span v-if="scope.row.payWay === 2">微信付款</span>
            <span v-if="scope.row.payWay === 3">支付宝付款</span>
            <span v-if="scope.row.payWay === 4">银联支付</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="memberLevel"
          label="本机构等级"
          align="center"
          min-width="20%"
        >
        </el-table-column>
        <el-table-column
          prop="payTime"
          label="付款时间"
          align="center"
          min-width="20%"
        >
        </el-table-column>
        <el-table-column
          prop="receiveTime"
          label="到账时间"
          align="center"
          min-width="20%"
        >
        </el-table-column>
        <el-table-column
          prop="expectedFee"
          label="应缴金额（元/年）"
          align="center"
          min-width="20%"
        >
        </el-table-column>
        <el-table-column
          prop="paidFee"
          label="实缴金额（元/年）"
          align="center"
          min-width="20%"
        >
        </el-table-column>
        <el-table-column
          prop="feeYears"
          label="缴费年限"
          align="center"
          min-width="20%"
        >
        </el-table-column>

        <el-table-column
          prop="feeState"
          label="资金状态"
          align="center"
          min-width="10%"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.feeState === 1">已开</span>
            <span v-if="scope.row.feeState === 2">未开</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="invoiceState"
          label="开票状态"
          align="center"
          min-width="20%"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.invoiceState === 1">待确认</span>
            <span v-if="scope.row.invoiceState === 2">已到账</span>
            <span v-if="scope.row.invoiceState === 3">到账异常</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="text-align: right; padding-top: 20px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="pageSize"
        :current-page="pageNo"
        :page-count="totalPage"
        :total="totalCount"
      >
      </el-pagination>
    </div>

  </div>
</template>
<script>
import { MemberList } from "@/http/api/InstitutionApi";

export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      loading: false,
      text: "",
      value1: "",
      value2: "",
      value3: "",
      value4: "",

      options1: [
        {
          value: "1",
          label: "会员等级",
        },
        {
          value: "2",
          label: "理事等级",
        },
        {
          value: "3",
          label: "常务理事等级",
        },
        {
          value: "4",
          label: "副会长等级",
        },
      ],

      options2: [
        {
          value: "1",
          label: "待确认",
        },
        {
          value: "2",
          label: "已确认",
        },
        {
          value: "3",
          label: "到账异常",
        },
      ],
      options3: [
        {
          value: "1",
          label: "已开",
        },
        {
          value: "2",
          label: "未开",
        },
      ],
      list: [],
      pageSize: 20,
      pageNo: 1,
      totalPage: 1,
      totalCount: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      // this.loading = true;
      MemberList({
        //       feeState (integer, optional): 资金状态 1.待确认 2.已到账 3.到账异常 ,
        // institutionsUserId (string, optional): 后台选择分支机构id ,
        // invoiceState (integer, optional): 发票状态 1.未开 2.已开 ,
        // keyword (string, optional): 账号、名称 ,
        // memberLevel (integer, optional): 当时缴费分支机构会员等级 1.会员 2.理事 3.常务理事 4.副会长 ,
        // orgName (string, optional): 所属分支机构名称 ,
        // pageNo (integer, optional): 当前页码，默认1 ,
        // pageSize (integer, optional): 页面大小，默认20 ,
        // payTimeBegin (string, optional): 开始付款时间 ,
        // payTimeEnd (string, optional): 结束付款时间 ,
        // payWay (integer, optional): 付款方式 1.银行账户付款 2.微信付款 3.支付宝付款 4.银联支付
        pageNo: 1,
        pageSize: 20,
        feeState: this.value3,
        invoiceState: this.value4,
        keyword: this.text,
        memberLevel: this.value2,
        payWay: 1,
      }).then((res) => {
        if (res.code === "000") {
          this.list = res.result.records;
        }
      });
    },
    search() {
      this.pageNo = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.pageNo = 1;
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.button {
  margin-left: 820px;
  height: 50px;
  display: flex;
  align-items: center;
}
.elseBut {
  width: 100%;
  height: 300px;
  background: url("../../assets/images/nodata.png") no-repeat center center;
}
</style>